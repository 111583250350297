export const Routing = {
  // Student
  Initial: "/",
  Login: "/login",
  Signup: "/signup",

  // Instructor

  // Admin
};
